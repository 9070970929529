<template>
	<article class="grid gap-4 bg-white shadow xs:rounded-xl p-4 @container/post">
		<Author :user="post.author" :created-at="post.created_at">
			<ClientOnly>
				<template v-if="client && (client.client_id == post.author.id || client.type == 'Administrator')">
					<nuxt-link class="flex items-center" :to="`/feed/${post.id}/edit`">
						<svg-icon class="cursor-pointer" name="edit" width="20" height="20"/>
					</nuxt-link>
					<svg-icon class="cursor-pointer" name="trash" width="20" height="20" @click="removeHandler" />
				</template>
			</ClientOnly>
		</Author>
		<slot>
			<nuxt-link :to="`/feed/${post.id}`">
				<h2 class="m-0 text-lg @lg:text-2xl line-clamp-2">{{ post.title }}</h2>

				<div class="post__excerpt" v-html="cleanSpaces(preview.html)"></div>

				<p class="text-green-600 text-sm" v-if="preview.more">Читать далее</p>
				
				<img v-if="coverSrc && !hideActions" class="object-cover transition-transform aspect-video rounded-xl" :src="coverSrc" :alt="post.title">
			</nuxt-link>
		</slot>
		<PostCounter v-if="!hideActions" class="-m-4 mt-0 p-4 border-t border-dashed text-gray-500" size="20" v-model:likes="post.likes_aggregate.aggregate.sum.value" v-model:isLiked="post.is_liked" :views="post.views" entity-type="Post" :entity-id="post.id" :comments="post.comments_aggregate?.aggregate.count"></PostCounter>
	</article>
</template>

<style>
	.post__excerpt h2 {
		@apply text-lg;
	}
	@screen sm {
		.post__excerpt h2 {
			@apply text-xl;
		}	
	}
	img[alt]:after{
		display: none;
	}
</style>

<script lang="ts" setup>
	import { cleanSpaces } from '#imports';
	import { useRemovePostMutation } from '@/composables/urql';

	const props = defineProps(['post', 'hideActions'])
	const route = useRoute();


	const client = useUser();
	const preview = trimHtml(props.post?.text, { limit: 140, remove_images: true });
	const [ img, coverSrc ] = findImagesFromHtml(props.post?.text) || [];

	const { executeMutation: removePost } = useRemovePostMutation();
	const removeHandler = async () => {
		await removePost({ id: props.post?.id });

		if (route.name == 'feed-id') {
			navigateTo('/feed', { replace: true })
		}
	}
</script>