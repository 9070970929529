<script setup>
    const emit = defineEmits(['update:likes', 'update:isLiked']);
    const props = defineProps([
        'entityType',
        'entityId',
        'comments',
        'isLiked',
        'likes',
        'views',
        'size'
    ]);
</script>

<template>
    <footer class="flex items-center gap-2 pt-4 border-t">
        <Likes class="mr-auto" v-bind="props" @update:likes="(data) => emit('update:likes', data)" @update:isLiked="(data) => emit('update:isLiked', data)" />
        <span class="button py-1 outline-0 gap-1">
            <svg-icon name="eye" width="20" height="20" />
            {{ views }}
        </span>
        <nuxt-link class="button py-1 outline-0 bg-slate-100 gap-1" :to="`/feed/${entityId}#comments`">
            <svg-icon name="chat-start" width="20" height="20" />
            {{ comments ?? 0 }}
        </nuxt-link>
    </footer>
</template>